import {CALCULATOR_API} from '@/config';
import * as NetworkUtils from '@/utilities/network-utils';

const state = () => ({
    iodReport: null,
    loading: false,
    requestTimeout: 100000,
});

const getters = {
    iodReport: (state) => state.iodReport,
    loading: (state) => state.loading,
    requestTimeout: (state) => state.requestTimeout,
};

const mutations = {
    setIodReport(state, report) {
        state.iodReport = report;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setRequestTimeout(state, requestTimeout) {
        state.requestTimeout = requestTimeout;
    },
};

const actions = {
    async fetchIodReport({commit, getters}, {lensOrderId, generatingIod, onTarget = false}) {
        commit('setLoading', true);
        let currentEndpoint = '/orders/iod';

        try {
            if (lensOrderId == undefined) {
                console.log('IodReport- Error lensOrderId undefined');
                throw new Error('IodReport- Error lensOrderId undefined');
            }

            let url = `${currentEndpoint}/${lensOrderId}?generatingIod=${generatingIod}`;

            if (onTarget) {
                url += `&onTarget=true`;
            }
            const response = await this._vm.$http.get(url, {
                cancelToken: NetworkUtils.addCancelTokenConnectionTimeout(getters.requestTimeout),
                timeout: getters.requestTimeout,
            });

            NetworkUtils.checkResponse(response, currentEndpoint);

            commit('setIodReport', response.data.results);
            commit('setLoading', false);
        } catch (error) {
            NetworkUtils.alertAndRethrow(error, commit, currentEndpoint);
        }
    },
    async fetchIodReportWithOverride(
        {commit, getters},
        {lensOrderId, serialNum, generatingIod, onTarget = false}
    ) {
        commit('setLoading', true);
        let currentEndpoint = '/calculations/iodoverride';

        try {
            if (lensOrderId == undefined) {
                console.log('IodReportWithOverride- Error lensOrderId undefined');
                throw new Error('IodReporWithOverride- Error lensOrderId undefined');
            }

            let url = `${currentEndpoint}/${lensOrderId}/${serialNum}?generatingIod=${generatingIod}`;

            if (onTarget) {
                url += `&onTarget=true`;
            }

            const response = await this._vm.$http.get(url, {
                cancelToken: NetworkUtils.addCancelTokenConnectionTimeout(getters.requestTimeout),
                timeout: getters.requestTimeout,
            });

            NetworkUtils.checkResponse(response, currentEndpoint);

            commit('setIodReport', response.data.results);
            commit('setLoading', false);
        } catch (error) {
            NetworkUtils.alertAndRethrow(error, commit, currentEndpoint);
        }
    },
    async confirmIodOverride({commit, getters}, {lensOrderId, serialNum, onTarget = false}) {
        commit('setLoading', true);
        let currentEndpoint = '/calculations/iodoverride';

        try {
            if (lensOrderId == undefined) {
                console.log('IodReportWithOverride- Error lensOrderId undefined');
                throw new Error('IodReporWithOverride- Error lensOrderId undefined');
            }

            let url = `${currentEndpoint}/${lensOrderId}/${serialNum}?confirmed=true`;

            if (onTarget) {
                url += `&onTarget=true`;
            }

            const response = await this._vm.$http.get(url, {
                cancelToken: NetworkUtils.addCancelTokenConnectionTimeout(getters.requestTimeout),
                timeout: getters.requestTimeout,
            });

            NetworkUtils.checkResponse(response, currentEndpoint);

            commit('setLoading', false);
        } catch (error) {
            console.log('confirmIodOverride error', error);
            NetworkUtils.alertAndRethrow(error, commit, currentEndpoint);
        }
    },
    async fetchIodCalculate({commit}, {targetAxis, eyeAxis}) {
        let url = `${CALCULATOR_API.BASE_URL}/iodCalculate?targetAxis=${targetAxis}&eyeAxis=${eyeAxis}`;

        const {data} = await this._vm.$http.get(url);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
