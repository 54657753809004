<template>
    <!-- This has to be a v-show instead of a v-if because the element has to
         remain in the DOM for the infinite scroll to work. Otherwise, if it
         is removed from the DOM the scroll listener cannot be restored. -->
    <div
        v-show="
            modalState.name === ModalStateNames.BASE ||
            modalState.name === ModalStateNames.SURGEON_SELECTED ||
            modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS
        "
        class="customers-list mt-2"
    >
        <b-row
            v-if="modalState.name === ModalStateNames.BASE"
            align-v="end"
            class="customers-list-header pb-2 mb-1"
        >
            <b-col cols="9">
                <span class="text-caption text-gray-dark">
                    {{ t('surgeonCustomerSelector_Customers') }}
                </span>
            </b-col>
            <b-col
                v-if="modalState.name === ModalStateNames.BASE"
                cols="3"
                class="text-caption text-right"
            >
                <b-link href="#" @click="onViewAllCustomers">
                    {{ t('surgeonCustomerSelector_ViewAll') }}
                </b-link>
            </b-col>
        </b-row>
        <hr v-if="modalState.name === ModalStateNames.BASE" />
        <div ref="customersListContents" :class="customersListClass">
            <b-row>
                <b-col>
                    <ul>
                        <li
                            v-for="(customer, index) in customersProcessed"
                            :key="`${customer.customerId}-${index}`"
                            class="pointer customers-list-contents-item"
                            @click="onCustomerSelected(customer)"
                        >
                            <div class="customers-list-contents-item-details">
                                <SearchedText
                                    :searchedItem="customer"
                                    searchedProperty="name"
                                ></SearchedText>
                                <div>
                                    <div class="text-gray-dark">
                                        <span>{{ customer.city }},</span>
                                        <span v-if="customer.state">{{ customer.state }},</span>
                                    </div>
                                    <div class="text-gray-dark">
                                        <span v-if="customer.country">
                                            {{ customer.country }},
                                        </span>
                                    </div>
                                    <em>
                                        <SearchedText
                                            class="ml-1 text-small"
                                            classBaseText="text-gray-dark"
                                            :searchedItem="customer"
                                            searchedProperty="ocosCustomerNumber"
                                        ></SearchedText>
                                    </em>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="modalReady && customersProcessed.length === 0"
                            class="customers-list-contents-item"
                        >
                            <div class="customers-list-contents-item-details">
                                <div>
                                    {{ t('surgeonCustomerSelector_NoCustomerFound') }}
                                    {{ searchText }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {debounce} from 'lodash';
import {ZoneCodes} from '@/constants/zone';
import SearchedText from '@/components/SearchedText.vue';

/**
 * The debounce delay for the scroll event (in milliseconds)
 */
const SCROLL_DEBOUNCE_DELAY = 500;

/**
 * Displays the list of customers for the Surgeon Customer Selector Modal.
 *
 * @emits scroll Emitted when a user scrolls the list. This event is debounced.
 * @emits view-all-customers Emitted when the user chooses to view all the surgeons.
 * @emits customer-selected-from-list Emitted when the user has selected a customer.
 */
export default {
    name: 'SurgeonCustomerSelectorCustomersList',
    components: {SearchedText},
    props: {
        /**
         * Determines if the modal has completed an action and is ready for
         * user input.
         */
        modalReady: {
            type: Boolean,
            required: true,
        },
        /**
         * The names of all the modal states.
         */
        ModalStateNames: {
            type: Object,
            required: true,
        },
        /**
         * The current modal state.
         */
        modalState: {
            type: Object,
            required: true,
        },
        /**
         * The current user's zone.
         */
        zone: {
            type: String,
            required: true,
        },
        /**
         * The text to search the list for.
         */
        searchText: {
            type: String,
            required: true,
        },
        /**
         * The customers after they have been processed with the current
         * search text.
         */
        customersProcessed: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            ZoneCodes,
        };
    },
    mounted() {
        this.$refs.customersListContents.addEventListener('scroll', this.onDebouncedScroll);
    },
    beforeDestroy() {
        this.$refs.customersListContents.removeEventListener('scroll', this.onDebouncedScroll);
    },
    computed: {
        customersListClass() {
            let customersListClass = '';
            if (this.modalState.name === this.ModalStateNames.VIEW_ALL_CUSTOMERS) {
                customersListClass = 'customers-list-contents-long';
            } else {
                customersListClass = 'customers-list-contents-short';
            }
            return customersListClass;
        },
    },
    methods: {
        /**
         * Handle when the user chooses to view the longer list of customers
         */
        onViewAllCustomers() {
            this.$emit('view-all-customers');
        },
        /**
         * Handle when the user has selected a customer from the list
         *
         * @param {Object} customer
         */
        onCustomerSelected(customer) {
            this.$emit('customer-selected-from-list', customer);
        },
        /**
         * Handle the scroll event by debouncing it and then emitting it
         */
        onDebouncedScroll: debounce(async function (event) {
            this.$emit('scroll', event);
        }, SCROLL_DEBOUNCE_DELAY),
    },
};
</script>
